import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, text: String, title: String };
  static targets = ["button"];

  connect() {
    if ("share" in navigator) {
      this.buttonTarget.classList.remove("hidden");
    } else {
      this.buttonTarget.classList.add("hidden");
    }
  }

  share(event) {
    event.preventDefault();

    navigator
      .share({
        url: this.urlValue,
        text: this.textValue,
        title: this.titleValue,
      })
      .then(() => {
        console.log("Sharing completed successfully.");
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Share action was canceled by the user.");
        } else {
          console.error("Error sharing:", error);
        }
      });
  }
}
