import { Controller } from "@hotwired/stimulus";

const vapidPublicKey = document.querySelector(
  'meta[name="vapid-public-key"]',
).content;

const getRegistration = async () => navigator.serviceWorker.ready;

const getSubscription = async () => {
  const registration = await getRegistration();

  return await registration.pushManager.getSubscription();
};

const createBackendSubscription = async (subscription) => {
  const {
    endpoint,
    keys: { p256dh, auth },
  } = subscription.toJSON();

  const body = JSON.stringify({
    push_subscription: { endpoint, p256dh_key: p256dh, auth_key: auth },
  });

  const createdSubscription = await fetch("/push_subscriptions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: body,
  });
};

const destroyBackendSubscription = async (subscription) => {
  const {
    endpoint,
    keys: { p256dh, auth },
  } = subscription.toJSON();

  const body = JSON.stringify({
    push_subscription: { endpoint, p256dh_key: p256dh, auth_key: auth },
  });

  const destrotedSubscription = await fetch(
    `/push_subscriptions/${subscription.auth_key}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: body,
    },
  );
};

const createSubscription = async () => {
  const registration = await getRegistration();

  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: vapidPublicKey,
  });

  await createBackendSubscription(subscription);

  return subscription;
};

const destroySubscription = async () => {
  const subscription = await getSubscription();

  if (subscription) {
    return await Promise.all([
      subscription.unsubscribe(),
      destroyBackendSubscription(subscription),
    ]);
  } else {
    return Promise.resolve(false);
  }
};

const hasNotificationPermission = async () => {
  console.log(
    `Permission to receive notifications has been ${Notification.permission}`,
  );

  switch (Notification.permission) {
    case "granted":
      return Promise.resolve(true);
    case "denied":
      return Promise.resolve(false);
    default:
      const permission = await Notification.requestPermission();
      console.log(`Permission to receive notifications has been ${permission}`);
      return Promise.resolve(permission === "granted");
  }
};

export default class extends Controller {
  static targets = ["toggleSubscriptionButton"];

  async connect() {
    console.log("connect");

    if (!window.PushManager) {
      this.setError("Push messaging is not supported in your browser");
    }

    if (!ServiceWorkerRegistration.prototype.showNotification) {
      this.setError("Notifications are not supported in your browser");
      console.error("Push messaging is not supported in your browser");
    }

    const subscription = await getSubscription();

    if (subscription) {
      this.setStatusSubscribed(subscription);
    } else {
      this.resetSubscriptionStatus();
    }
  }

  async toggleSubscribe(event) {
    event.preventDefault();
    const enabled =
      this.toggleSubscriptionButtonTarget.getAttribute("data-enabled") ===
      "true";

    this.toggleSubscriptionButtonTarget.disabled = true;

    if (enabled) {
      await this.unsubscribe();
    } else {
      await this.trySubscribe();
    }

    this.toggleSubscriptionButtonTarget.disabled = false;
  }

  async trySubscribe() {
    console.log("trySubscribe");
    this.setError(null);
    this.toggleSubscriptionButtonTarget.disabled = true;

    let permissionGranted = await hasNotificationPermission();

    if (permissionGranted) {
      this.subscribe();
    } else {
      this.permissionDenied();
    }
  }

  async subscribe() {
    console.log("subscribe", "start");

    const subscription =
      (await getSubscription()) || (await createSubscription());

    if (subscription) {
      this.setStatusSubscribed(subscription);
    } else {
      console.error("Web push subscription failed");
    }

    console.log("subscribe", "finish", subscription);
  }

  async unsubscribe() {
    console.log("unsubscribe", "start");
    const result = await destroySubscription();
    this.resetSubscriptionStatus();
    console.log("unsubscribe", "finish", result);
  }

  permissionDenied() {
    console.log("permissionDenied");
    this.setError(
      "Permission for notifications is denied. Please change your browser settings if you wish to support web push.",
    );
  }

  setStatusSubscribed(subscription) {
    console.log("setSubscriptionStatusSubscribed", subscription.toJSON());

    this.toggleSubscriptionButtonTarget.setAttribute("data-enabled", "true");
    this.dispatch("subscription-changed", { detail: { subscription } });
  }

  resetSubscriptionStatus() {
    console.log("resetSubscriptionStatus");

    this.toggleSubscriptionButtonTarget.setAttribute("data-enabled", "false");
    this.dispatch("subscription-changed", { detail: { subscription: null } });
  }

  disconnect() {
    console.log("disconnect");
  }

  setError(error) {
    if (error) {
      console.error("setError", error);
      this.dispatch("subscription-error", { detail: { error } });
    }
  }
}
