const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    // Register the service worker
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(function (registration) {
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );
        navigator.serviceWorker.ready;
      })
      .catch(function (error) {
        console.log("Service Worker registration failed:", error);
      });
  }
};

export default registerServiceWorker;
