import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["flashContainer"];

  connect() {
    this.handleSubscriptionError = this.handleSubscriptionError.bind(this);
  }

  async handleSubscriptionError(event) {
    const { error } = event.detail;
    const response = await fetch("/flash_messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ message: error, type: "alert" }),
    });

    if (response.ok) {
      const html = await response.text();
      this.flashContainerTarget.innerHTML = html;
    }
  }
}
