import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "span"];

  toggle(event) {
    event.preventDefault();
    this.buttonTarget.setAttribute(
      "data-enabled",
      this.buttonTarget.getAttribute("data-enabled") === "true"
        ? "false"
        : "true",
    );
    this.updateSwitch();
  }

  updateSwitch() {
    const enabled = this.buttonTarget.getAttribute("data-enabled") === "true";
    const button = this.buttonTarget;
    const span = this.spanTarget;

    if (enabled) {
      button.setAttribute("aria-checked", "true");
      button.classList.remove("bg-gray-200");
      button.classList.add("bg-indigo-600");
      span.classList.remove("translate-x-0");
      span.classList.add("translate-x-5");
    } else {
      button.setAttribute("aria-checked", "false");
      button.classList.remove("bg-indigo-600");
      button.classList.add("bg-gray-200");
      span.classList.remove("translate-x-5");
      span.classList.add("translate-x-0");
    }
  }
}
